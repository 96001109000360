import { useNuxtApp } from '#app'
import type { Diary, Week, WeekNutrient } from '@/types/diary'
import { WeekFazaLetter, WeekFaza } from '@/types/diary'

/**
 * useUser — logic (CRUD, likes, subscriptions) for the "User" entity
 */

export function useUser() {
  const { $api, $popup, $follow, $convert } = useNuxtApp()
  
  async function follow(user: User) {
    let state = 1
    try {
      await $follow.toggle('user', user.id, state, {
        type: 'user',
        id: user.id,
        avatar: user.avatar_small,
        name: user.name,
        link: user.link
      })

      user.is_follow = state ? true : false

  
      if(user.item_stat){
        user.item_stat.cnt_followers += state ? 1 : -1
      }
    } catch (error) {
      $popup.error('Error')
    }
  }
  
  async function unfollow(user: User) {
    let state = 0
    try {
      await $follow.toggle('user', user.id, state, {
        type: 'user',
        id: user.id,
        avatar: user.avatar_small,
        name: user.name,
        link: user.link
      })


      user.is_follow = state ? true : false

  
      if(user.item_stat){
        user.item_stat.cnt_followers += state ? 1 : -1
      }
    } catch (error) {
      $popup.error('Error')
    }
  }


  function getProfileSettingsTabs(user: User){

    let items = [];

    items.push({
      id: 'profile',
      tr: 'profile_menu_edit_profile_title',
      link: user.link + '/settings'
    });
  
    items.push({
      id: 'cookies',
      tr: 'profile_menu_edit_profile_cookie_title',
      link: user.link + '/settings/cookies'
    });
  
    items.push({
      id: 'socials',
      tr: 'profile_menu_edit_profile_socials',
      link: user.link + '/settings/socials'
    });
  
  
    items.push({
      id: 'password',
      tr: 'profile_menu_edit_profile_button_password',
      link: user.link + '/settings/password'
    });
  
    if(user.item_stat?.cnt_bn){
      items.push({
        id: 'banned',
        tr: 'profile_menu_edit_profile_blocked_title',
        link: user.link + '/settings/banned'
      });
    }
  
    items.push({
      id: 'delete',
      tr: 'profile_menu_edit_profile_delete_title',
      link: user.link + '/settings/delete'
    });
  
    return items;
  }
  
  function getProfileTabs(user: User){

    let items = [];

    items.push({
      id: 'info',
      tr: 'universal_info',
      link: user?.link + '',          
      count: '',
      icon: '',
    });
  
    if(user?.id === useAuth().getId()){
      items.push({
        id: 'diaries',
        tr: 'universal_plurals_diaries_capital',
        link: user?.link + '/diaries',
        count: user?.item_stat.cnt_reports_owner,
        icon: '',
      });
    }else{
  
      if(user?.item_stat.cnt_reports && user?.item_stat.cnt_reports > 0){
        items.push({
          id: 'diaries',
          tr: user?.item_stat.cnt_reports > 0 ? 'universal_plurals_diaries_capital' : 'universal_singular_diary_capital',
          link: user?.link + '/diaries',
          count: user?.item_stat.cnt_reports,
          icon: '',
        });
      }
    }


    if(useAuth().getAccess('view_extra') && (useAuth().isOwner(user?.id) || user?.item_stat?.cnt_reports > 0)){
      items.push({
        id: 'shorts',
        tr: null,
        name: 'Shorts',
        link: user?.link + '/shorts',
        count: 0,
        class: 'is-new',
        icon: '',
      });
    }
  
    if(user?.item_stat.cnt_reviews && user?.item_stat.cnt_reviews > 0){
      items.push({
        id: 'reviews',
        tr: user?.item_stat.cnt_reviews > 0 ? 'universal_plurals_reviews_capital' : 'universal_singular_review_capital',
        link: user?.link + '/reviews',
        count: user?.item_stat.cnt_reviews,
        icon: '',
      });
    }
  
    if(user?.item_stat.cnt_following && user?.item_stat.cnt_following > 0){
      items.push({
        id: 'following',
        tr: 'universal_category_following',
        link: user?.link + '/following',
        count: user?.item_stat.cnt_following,
        icon: '',
      });
    }
  
    if(user?.item_stat.cnt_followers && user?.item_stat.cnt_followers > 0){
      items.push({
        id: 'followers',
        tr: 'universal_category_followers',
        link: user?.link + '/followers',
        count: user?.item_stat.cnt_followers,
        icon: '',
      });
    }
  
    return items;
  }



  return {
    follow,
    unfollow,
    getProfileTabs,
    getProfileSettingsTabs,
  }
}